#FormContainer{
    text-align: center;
    background: #302f2f;
    box-shadow: -5px -5px 30px 5px rgba(106, 152, 156, .25), 5px 5px 30px 5px rgba(134, 114, 198, .25);
    border-radius: 3em;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10% 5% 10% 5%;
    flex: 1;
    display: flex;
    height: 50%;
}

#PasswordResetSubmitButton{
    display: flex;
    margin-top: 3%;
    height: 40%;
    background-color: #6a989c;
    border-radius: 20px;
    filter: drop-shadow(5px 5px 4px #4444dd);
}

#EmailVerificationButton {
    display: flex;
    flex: .55;
    background-color: #75abaf;
    width: 50%;
    border-radius: 20px;
    filter: drop-shadow(2px 3px 4px #4444dd);
    margin: auto;
    margin-top: 10%;
    padding: 4%;
}

#EmailVerificationFailedText {
    display: flex;
    flex: .50;
    width: 75%;
    color: #9ac1d6;
    font-size: 1.25em;
    font-family: Thonburi, sans-serif;
}

#EmailVerificationSuccessfulText {
    display: flex;
    flex: .50;
    color: #11dce3;
    font-size: 1.25em;
    font-family: Thonburi, sans-serif;
}


