.extendedNetworkMessage {
    border: 2px solid black;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    padding: 4%;
    margin: 2.5%;
    font-weight: bold
}

@media (max-width: 820px) {

    .extendedNetworkMessage {
        display: none;
    }

}