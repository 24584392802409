.containerTop {
    width: 100%;
    background-color: rgb(48,93,150);
    padding-top: .75%;
    padding-bottom: .75%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchBarContainer {
    display: flex;
    flex: 1;
    justify-content: center
}

.topMenuLeftSideContainer {
    font-size: 20px;
    color: white;
    padding-left: 2.5%;
    flex-direction: row;
    display: flex;
    width: 100%;
    align-items: center;
}

.topMenuRightSideContainer {
    display: flex;
    flex: 1;
    margin: 0 auto;
}


@media (max-width: 820px) {

    .searchBarContainer {
        display: none;
    }

    .topMenuLeftSideContainer {
        font-size: 20px;
        color: white;
        padding-left: 5%;
        flex-direction: row;
        display: flex;
        width: 100%;
        align-items: center;
    }

    .topMenuRightSideContainer {
        display: flex;
        flex: 1;
        margin: 0 auto;
        padding-right: 2.5%;
    }

}