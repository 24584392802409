.FriendCommentImage {
    width: 80%;
}

.LeftSideContainer {
    flex-direction: column;
    display: flex;
    width: 25%;
    background-color: rgb(225, 147, 111);
    justify-content: center;
    align-items: center;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-bottom: 1%
}

.RightSideContainer {
    padding-left: 1%;
    background-color: rgb(246, 204, 159);
    width: 100%;
    text-align: left;
    padding-right: 1%;
    padding-bottom: 2.5%;
}

@media (max-width: 820px) {

    .FriendCommentImage {
        width: 100%;
    }

    .LeftSideContainer {
        flex-direction: column;
        display: flex;
        width: 30%;
        background-color: rgb(225, 147, 111);
        justify-content: center;
        align-items: center;
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-bottom: 1%
    }

    .RightSideContainer {
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-bottom: 2.5%;
        background-color: rgb(246, 204, 159);
        width: 100%;
        text-align: left
    }
}