.SkullImage {
    width: 15%;
    margin-top: 2.5%;
}

@media (max-width: 820px) {

    .SkullImage {
        width: 45%;
        margin-top: 5%;
    }

}