.FriendSpaceItemContainer {
    align-items: center;
    justify-content: center;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 15%;
    background: none;
    border: none;
}

@media (max-width: 820px) {

    .FriendSpaceItemContainer {
        align-items: center;
        justify-content: center;
        padding: 1%;
        margin-top: 1%;
        margin-bottom: 1%;
        width: 25%;
        background: none;
        border: none;
    }


}