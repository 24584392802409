.PierImage {
    margin-top: 2.5%;
    object-fit: scale-down;
    height: 50vh;
}

@media (max-width: 820px) {
    .PierImage {
        margin-top: 0;
        width: 100%;
    }

}