#loginContainer {
    width: 60%;
    text-align: center;
    padding: 2.5em;
    background-color: rgb(48,93,150);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    overflow: hidden;
    border: 3px solid #989797;
    min-height: 500px;
    margin: 5% auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 820px) {

    #loginContainer{
        height: 100vh;
        width: 100%;
        padding:0;
        border-radius: 0px;
        box-shadow: none;
        margin: 0 auto;
    }

}
