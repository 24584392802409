.ViewPicsAndVideosLinksContainer {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%
}

@media (max-width: 820px) {

    .ViewPicsAndVideosLinksContainer {
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 1%;
        padding-bottom: 5%
    }

}