
.heart {
    position: fixed;
    font-size: 1.5rem;
    top: -1vh;
    transform: translateY(0);
    animation: fall 3s linear forwards;
}

@keyframes fall {
    from {
        transform: translateY(-20vh) translateX(-10vw);
    }
    to {
        transform: translateY(105vh) translateX(2.5vw);
    }
}

.PageBackground {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.TopMenuLink {
    color: #f2fbff;
    font-size: 15px;
    font-family: Thonburi, serif;
}

.BodyContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-bottom: 5%;
}


.RightColumn {
    width: 60%;
    justify-content: center;
    align-items: center;
}

.LeftColumn {
    width: 40%;
}

.topMenuLinkSeparator {
    margin-left: 1%;
    margin-right: 1%;
    color: rgb(48, 93, 150);
    font-weight: bold;
}

.PortraitViewBodyContainer {
    border: 2px solid rgb(48, 93, 150);
    align-items: center;
    justify-content: center;
    text-align: left;
    margin: 2.5%;
}

.PortraitViewBackButton {
    padding: .5%;
    color: black;
    margin-right: 2.5%;
    border-radius: 0;
    -webkit-appearance: none;
    background-color: rgb(239, 239, 239);
}

a:hover {
    color: blue;
}


@media (max-width: 820px) {

    .BodyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 15%;
    }

    .LeftColumn {
        width: 90%;
    }

    .RightColumn {
        width: 90%;
        justify-content: center;
        align-items: center;
    }

    .TopMenuLink {
        color: #f2fbff;
        font-size: 18px;
        font-family: Thonburi, serif;
    }

    .topMenuLinkSeparator {
        margin-left: 1%;
        margin-right: 1%;
        color: rgb(48, 93, 150);
        font-weight: bold;
        font-size: 18px;
    }

    .PortraitViewBodyContainer {
        border: 2px solid rgb(48, 93, 150);
        align-items: center;
        justify-content: center;
        text-align: left;
        margin: 5% 2.5% 2.5%;
    }

    .PortraitViewBackButton {
        padding: 3%;
        color: black;
        margin-right: 5%;
        border-radius: 0;
        -webkit-appearance: none;
        background-color: rgb(239, 239, 239);
        margin-top: 1%;
        margin-bottom: 1%;
    }

}
