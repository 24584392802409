.mobileContainerTop {
    display: none;
}

.mobileSearchBarContainer {
    display: none;
}

@media (max-width: 820px) {

    .mobileSearchBarContainer {
        display: flex;
        flex: 1;
        justify-content: center;
        padding-top: 1%;
        padding-bottom: 1%;
        align-items: center;
    }

    .mobileContainerTop {
        width: 100%;
        background-color: rgb(48,93,150);
        padding-top: .75%;
        padding-bottom: .75%;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
    }


}