.mobileExtendedNetworkMessage {
    border: 2px solid black;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    padding: 4%;
    margin: 10%;
    margin-top: 5%;
    margin-bottom: 0;
    font-weight: bold
}

@media (min-width: 820px) {

    .mobileExtendedNetworkMessage {
        display: none;
    }

}
